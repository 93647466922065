import React, { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import { useForm } from 'react-hook-form';
import { Title, BasicButton, ErrorMessage, A } from '@nuage/ui-components';
import OxBlob from '@molecules/visual/oxevaBlob';

import MainLayout from '@organisms/main/mainLayout';
import LogoIconC from '@images/svg/logo-color.svg';

import ComplexField from '@molecules/interactive/complexField';

import lang from '@utils/lang';
import NuageClient from '@utils/client';

import './signin.scss';

const SignIn = ({ location }) => {
    const { _string } = lang;

    const [clObj, setClObj] = useState(null);
    const [loading, setLoading] = useState(false);
    const [onRedirectError, setOnRedirectError] = useState(false);
    const [offsetPage] = useState(0);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
        trigger,
    } = useForm({
        mode: 'onTouched',
    });

    const orgErrors = useMemo(() => ({
        check: {
            404: _string('signin_error_notfound'),
            500: _string('signin_error_server'),
            default: _string('signin_error_server'),
        },
        redirect: {
            404: _string('error_org_notFound'),
            500: _string('error_org_server'),
            default: _string('error_org_server'),
        },
    }), [_string]);

    const checkOrg = useCallback(({ org }) => {
        if (!clObj) { return; }
        setLoading(true);
        clObj.cl.checkOrganization({ name: org }).onReady((error) => {
            setLoading(false);
            if (error) {
                setError('org', {
                    type: 'manual',
                    message: orgErrors.check[error.status] || orgErrors.check.default,
                });
            } else {
                window.location.href = `http://${org}.${process.env.APP_BASE_DOMAIN}`;
            }
        });
    }, [clObj, setError, orgErrors]);

    const onChangeCheck = useCallback((evt) => {
        if (!onRedirectError) { return; }
        const { value } = evt.target;
        if (value) {
            clearErrors('org');
            trigger();
            setOnRedirectError(false);
        }
    }, [clearErrors, onRedirectError, trigger]);

    useEffect(() => {
        if (location?.search) {
            const params = new URLSearchParams(location.search);
            const org = params.get('org');
            const error = params.get('error');
            if (error && org) {
                setValue(
                    'org',
                    org,
                );
                setError('org', {
                    type: 'manual',
                    message: orgErrors.redirect[error.status] || orgErrors.redirect.default,
                });
                setOnRedirectError(true);
            }
        }
    }, [location.search, setError, setValue, orgErrors]);

    useEffect(() => {
        const newClObj = new NuageClient();
        setClObj(newClObj);
    }, []);

    return (
        <MainLayout title={_string('signin_titleTab')} socials={_string('signin_socials')} classTitle="signin" footer={false}>
            <LogoIconC className="signin__icon" />
            <div
                className="signin__block-parent"
            >
                <OxBlob
                    color="primary"
                    mainShape="M154.7,69.7c12.9,21.1,17.9,48.1,7.4,68.1s-36.3,33.3-58.6,31.3-41.2-19.2-55.4-41.5S24.3,78,33.7,59,71.5,29.2,96.2,31.4,141.8,48.7,154.7,69.7Z"
                    className="signin__blob signin__blob__main"
                    offset={offsetPage}
                />
                <div
                    className="signin__block"
                >
                    <form onSubmit={handleSubmit(checkOrg)} className="signin__block__form">
                        <div className="signin__block__title">
                            <Title size="xlarge">
                                { _string('signin_form_title') }
                            </Title>
                            <p className="signin__block__title__subtitle">
                                { _string('signin_form_description') }
                            </p>
                        </div>
                        <div className="signin__block__field">
                            <ComplexField
                                name="org"
                                id="org"
                                rules={{
                                    required: _string('signin_error_required'),
                                }}
                                controlForm={control}
                                displayErrors={false}
                                onFieldChange={onChangeCheck}
                            />
                            <p>{`.${process.env.APP_BASE_DOMAIN}`}</p>
                        </div>
                        { errors.org && (
                            <ErrorMessage opened>
                                {errors.org.message}
                            </ErrorMessage>
                        )}
                        <BasicButton
                            onClick={handleSubmit(checkOrg)}
                            disabled={Object.keys(errors).length !== 0 || loading}
                        >
                            {_string('signin_next_btn')}
                        </BasicButton>
                    </form>
                    <p className="signin__block__details">{_string('signin_detailText')}</p>
                    <div className="signin__block__signup">
                        <p>{_string('signin_signup_text')}</p>
                        <A TypeLink={Link} linkProps={{ to: '/signup' }}>
                            {_string('signin_signup_cta')}
                        </A>
                    </div>
                </div>
                <OxBlob
                    color="primary-hover"
                    mainShape="M154.7,69.7c12.9,21.1,17.9,48.1,7.4,68.1s-36.3,33.3-58.6,31.3-41.2-19.2-55.4-41.5S24.3,78,33.7,59,71.5,29.2,96.2,31.4,141.8,48.7,154.7,69.7Z"
                    className="signin__blob signin__blob__secondary"
                    offset={offsetPage}
                />
            </div>
        </MainLayout>
    );
};

SignIn.propTypes = {
    location: PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.object,
    ])),
};

SignIn.defaultProps = {
    location: null,
};

export default SignIn;
